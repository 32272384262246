/* ==========================================================================
   Steel theme skin
   ========================================================================== */

/* Colors */
$base-color: #3a4750 !default;
$text-color: #303841 !default;
$accent-color: #861115 !default;
$background-color: #eee !default;
$code-background-color: #fff !default;
$border-color: rgba($text-color, 0.25) !default;
$overlay-color: #fff !default;

/* Syntax highlighting (base16) colors */
$base00: #1d1f21 !default;
$base01: #282a2e !default;
$base02: #373b41 !default;
$base03: #969896 !default;
$base04: #b4b7b4 !default;
$base05: #c5c8c6 !default;
$base06: #e0e0e0 !default;
$base07: #ffffff !default;
$base08: #cc6666 !default;
$base09: #de935f !default;
$base0a: #f0c674 !default;
$base0b: #b5bd68 !default;
$base0c: #8abeb7 !default;
$base0d: #81a2be !default;
$base0e: #b294bb !default;
$base0f: #a3685a !default;
